@media (max-width: 991.98px)
{
    .dashboard
    {
        width: 100%;
        position: absolute;
    }
}

@media (min-width: 992px)
{
    #sidepanel
    {
        display: block;
    }
}

@mixin transition-ease-out {
    transition:
    {
        duration: 0.3s;
        timing-function: ease-out;
    }
}

.navbar
{
    font-size: 1.25rem;
    font-weight: 600;
    position: sticky;
    top: 0px;
    z-index: 1;
}

.navbar-collapsed-container
{
    gap: 1rem;
}

.navbar-collapsed-container button img
{
    height: 2rem;
    width: auto;
}

.nav-link
{
    color: $white;
    border-radius: 0.5rem;
    transition-property: color, background-color;
    @include transition-ease-out;

    &:hover
    {
        background-color: $white;
        color: $black;
        transition-property: color, background-color;
        @include transition-ease-out;
    }
}

.navbar-toggler
{
    display: flex;
    border: none ;
    border-radius: 50%;
    width: 3.5rem;
    transition-property: background-color;
    @include transition-ease-out;

    &:hover
    {
        background-color: $light-purple;
        transition-property: background-color;
        @include transition-ease-out;
    }

    &:focus, &:active
    {
        outline: none;
        box-shadow: none;
    }
}

.navbar-toggler img
{
    width: 2rem;
    height: 2rem;
}

.navbar-brand img
{
    height: 2.5rem;
    width: auto;
}

#account-dropdown
{
    transition-property: color, background-color;
    @include transition-ease-out;

    &:hover
    {
        background-color: $white;
        color: $black;
        transition-property: color, background-color;
        @include transition-ease-out;
    }
}

.dropdown-menu
{
    border-radius: 0.5rem;
    border: none;
}

.dropdown-item
{
    font-size: 1.25rem;
    font-weight: 600;
    color: $white;
    border-radius: 0.5rem;

    &:hover
    {
        background-color: $light-purple;
        color: $white;
    }
}

.sidepanel-and-dashboard-div {
    overflow-y: scroll;
}

#sidepanel
{
    display: flex;
    row-gap: 0.75rem;
    font-weight: 400;
    font-size: 1.25rem;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
    top: 0;
    transition-property: width, min-width;
    @include transition-ease-out;
}

#sidepanel a
{
    text-decoration: none;
    color: $white;
}

.sidepanel-btn
{
    border-radius: 0.5rem;
    transition-property: background-color;
    @include transition-ease-out;

    &:hover
    {
        background-color: $light-purple;
        transition-property: background-color;
        @include transition-ease-out;
    }
}

.sidepanel-float-button
{
    width: 3rem;
    height: 3rem;
    border-radius: 25%;
    background-color: $black;
    box-shadow: 2px 2px 4px $light-purple;
    bottom: 3rem;
    transition-property: background-color, left;
    @include transition-ease-out;
    z-index: 1;

    &:hover
    {
        background-color: $light-purple !important;
        transition-property: background-color, left;
        @include transition-ease-out;
    }
}

.sidepanel-open
{
    width: 12rem;
    min-width: 12rem;
}

.sidepanel-open-btn
{
    left: 13rem;
}

.sidepanel-open-img
{
    content: url(/img/sidepanel_btn_open.svg);
}

.sidepanel-close
{
    width: 0rem;
    min-width: 0rem;
}

.sidepanel-close-btn
{
    left: 2rem;
}

.sidepanel-close-img
{
    content: url(/img/sidepanel_btn_close.svg);
}

.dashboard
{
    flex-grow: 1;
}
