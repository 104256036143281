// Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

/* components CSS */
@import 'components';

/* color utils */
.bg-kimbel-purple { background-color: $kimbel-purple; }
.bg-light-purple { background-color: $light-purple; }
.bg-white { background-color: $white; }
.bg-black { background-color: $black; }
.text-kimbel-purple { color: $kimbel-purple; }
.text-light-purple { color: $light-purple; }
.text-white { color: $white; }
.text-black { color: $black; }

/* global settings */
header
{
    height: 4rem;
}

body
{
    min-width: auto;
    font-family: 'Poppins';
    font-weight: 300;
    height: 100vh;
}

#app
{
    position: relative;
    display: flex;
    flex-flow: column;
    height: inherit;
}

.navbar {
    background-color: $kimbel-purple;
}

.btn-primary {
    background-color: $kimbel-purple;
    border-color: $kimbel-purple;
}

.barcode {
    white-space: pre;
}

tr[data-p-highlight="true"]:not(:has(*[data-pc-name='rowcheckbox'])) {
    background-color: $blue;
    color: $white;
}

table.recon-table input[data-pc-name='inputtext'] {
    padding: 0.2em 0.35em;
    font-size: 0.9em;
}

// Hide extra checkbox image on primevue datatable
div[data-pc-name='headercheckbox'] > div[data-pc-section='box'],
div[data-pc-name='rowcheckbox'] > div[data-pc-section='box'] {
    display: none;
}

.recon-investigation-table-card .card-body {
    overflow-y: auto;
}

table.recon-table thead {
    font-size: 0.8em;
}

table.recon-table tbody {
    font-size: 0.75em;
}

button[data-pc-section='rowtoggler'] {
    border: none;
    background: none;
}

div[data-pc-name='checkbox'] div[data-pc-section='box'] {
    display: none;
}

/* DataTable */
button[data-pc-section="firstpagebutton"],
button[data-pc-section="previouspagebutton"],
button[data-pc-section="pagebutton"],
button[data-pc-section="nextpagebutton"],
button[data-pc-section="lastpagebutton"] {
    background-color: #2a1770;
    color: white;
    border: none;
    padding: 0.5em 1em;
}

button[data-pc-section="firstpagebutton"] {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

button[data-pc-section="lastpagebutton"] {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

button[data-p-highlight="true"] {
    background-color: #9683de;
}

ul[data-pc-section="filterrowitems"] {
    background-color: white;
    border: 1px solid $gray-600;
    border-radius: 5px;
    list-style-type: none;
    padding: 0;
}

li[data-pc-section="filterrowitem"] {
    padding: 0.25em 1em;
    transition: all 0.3s ease;

    &:not(:last-child) {
        border-bottom: 1px solid $gray-600;
    }

    &:hover {
        background-color: $blue;
        color: $white;
    }
}

.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
    overflow-y: auto;
}

.pop-up .card {
    width: 40%;
}

@media (max-width: 1199.98px) {
    .pop-up .card {
        width: 60%;
    }
}

@media (max-width: 991.98px) {
    .pop-up .card {
        width: 75%;
    }
}

@media (max-width: 767.98px) {
    .pop-up .card {
        width: 100%;
    }
}
